import axios from 'axios';

const BASE_URL = 'http://www.straight-a.com/api/v1';
// const BASE_URL = 'http://localhost:8000/api/v1';
const TOKEN_KEY = 'sa_app_auth_token';

// Create axios instance with custom config
const api = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Request interceptor
api.interceptors.request.use(
    async (config) => {
        const authTokens = localStorage.getItem(TOKEN_KEY);
        if (authTokens) {
            const { access } = JSON.parse(authTokens);
            config.headers.Authorization = `Bearer ${access}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

export default api;
