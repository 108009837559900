import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from "@/utils/api";
import { useDispatch } from 'react-redux';
import { fetchUserProfile, setUserProfile } from '@/redux/slices/userSlice';

// Add a unique prefix for your application
const TOKEN_KEY = 'sa_app_auth_token';

export const AuthContext = createContext({
    isAuthenticated: false,
    tokens: null,
    login: async () => {},
    register: async () => {},
    logout: async () => {},
    refreshToken: async () => {},
});
// const BASE_URL = 'http://www.straight-a.com';

export const AuthProvider = ({ children }) => {
    const dispatch = useDispatch();
    const [tokens, setTokens] = useState(() => {
        // Initialize tokens from localStorage if they exist
        const savedTokens = localStorage.getItem(TOKEN_KEY);
        return savedTokens ? JSON.parse(savedTokens) : null;
    });
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    // Initialize auth state
    useEffect(() => {
        const initializeAuth = async () => {
            try {
                const savedTokens = localStorage.getItem(TOKEN_KEY);
                if (savedTokens) {
                    const parsedTokens = JSON.parse(savedTokens);
                    setTokens(parsedTokens);
                    api.defaults.headers.common['Authorization'] = `Bearer ${parsedTokens.access}`;
                }
            } catch (error) {
                console.error('Auth initialization error:', error);
                localStorage.removeItem(TOKEN_KEY);
                setTokens(null);
            } finally {
                setLoading(false);
            }
        };

        initializeAuth();
    }, []);

    // Store tokens in localStorage whenever they change
    useEffect(() => {
        if (tokens) {
            console.log(tokens)
            localStorage.setItem(TOKEN_KEY, JSON.stringify(tokens));
            api.defaults.headers.common['Authorization'] = `Bearer ${tokens.access}`;
        } else {
            localStorage.removeItem(TOKEN_KEY);
            delete api.defaults.headers.common['Authorization'];
        }
        setLoading(false);
    }, [tokens]);

    // Load user data if we have tokens
    // useEffect(() => {
    //     const loadUser = async () => {
    //         if (tokens?.access) {
    //             try {
    //                 const response = await api.get('/user/profile/');
    //                 setUser(response.data);
    //             } catch (error) {
    //                 console.error('Failed to load user:', error);
    //                 // If we can't load the user, clear the auth state
    //                 setUser(null);
    //                 setTokens(null);
    //             }
    //         }
    //         setLoading(false);
    //     };
    //
    //     loadUser();
    // }, [tokens]);

    const login = async (email, password) => {
        try {
            const response = await api.post('/user/login/', {
                'email': email,
                'password': password,
            });

            const data = response.data;
            console.log(data);
            if (data.code !== 10000) {
                const message = data.message || 'Login failed';
                throw new Error(message);
            }

            const { tokens: newTokens, user } = data.body;
            setTokens(newTokens);

            // Set the user profile directly from login response
            dispatch(setUserProfile(user));

            return { success: true };
        } catch (error) {
            console.error('Login error:', error);
            // If the error comes from the API response
            if (error.response?.data?.message) {
                throw new Error(error.response.data.message);
            }
            // If it's a network error or other type of error
            throw new Error(error.message || 'Login failed. Please try again.');
        }
    };

    const register = async (email, password, referral_code) => {
        try {
            const response = await api.post('/user/register/', {
                'email': email,
                'password': password,
                'referral_code': referral_code,
            });

            const data = response.data;
            if (data.code !== 10000) {
                const message = data.message || 'Registration failed';
                throw new Error(message);
            }

            // After successful registration, automatically log in
            return login(email, password);
        } catch (error) {
            console.error('Registration error:', error);
            if (error.response?.data?.message) {
                throw new Error(error.response.data.message);
            }
            throw new Error(error.message || 'Registration failed. Please try again.');
        }
    };

    const logout = async () => {
        try {
            // Only attempt to call logout API if we have a valid token
            if (tokens?.access) {
                try {
                    await api.post('/user/logout/', {});
                } catch (error) {
                    // Ignore any errors during logout API call
                    console.log('Logout API call failed:', error);
                }
            }
        } finally {
            // Always clear local state regardless of API call success
            setTokens(null);
            dispatch(setUserProfile(null));
            navigate('/login');
        }
    };

    const refreshToken = async () => {
        try {
            if (!tokens?.refresh) {
                throw new Error('No refresh token available');
            }

            const response = await api.post('/user/refresh/', {
                refresh: tokens.refresh
            });

            const newTokens = response.data.tokens;
            setTokens(newTokens);

            return newTokens.access;
        } catch (error) {
            console.error('Token refresh failed:', error);
            await logout();
            throw error;
        }
    };

    // Update the interceptor setup
    useEffect(() => {
        const interceptor = api.interceptors.response.use(
            (response) => response,
            async (error) => {
                const originalRequest = error.config;

                // Skip token refresh for these endpoints
                const skipRefreshFor = [
                    '/user/refresh/',
                    '/user/logout/',
                    '/user/login/'
                ];

                // Check if it's a 401 error, not already retrying, and not a skipped endpoint
                if (error.response?.status === 401 
                    && !originalRequest._retry 
                    && !skipRefreshFor.includes(originalRequest.url)) {
                    originalRequest._retry = true;

                    try {
                        const newAccessToken = await refreshToken();
                        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                        return api(originalRequest);
                    } catch (refreshError) {
                        // If refresh fails, logout silently without making the API call
                        setTokens(null);
                        dispatch(setUserProfile(null));
                        navigate('/login');
                        throw refreshError;
                    }
                }

                return Promise.reject(error);
            }
        );

        return () => {
            api.interceptors.response.eject(interceptor);
        };
    }, [refreshToken, logout, navigate, dispatch]); // Updated dependencies

    if (loading) {
        return <div>Loading...</div>; // Or your loading component
    }

    const contextValue = {
        tokens,
        login,
        register,
        logout,
        refreshToken,
        isAuthenticated: !!tokens?.access
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
